import { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import api from '../../api/bw-api'
import { ScheduleItem, PrinterType, PrintRequest } from '@/global-components/types'
import { useParams } from "react-router-dom";
import { Table, TableHeader, TableBody, TableRow, TableHead, TableCell } from '@/global-components/components/ui/table';
import { Button } from '@/global-components/components/ui/button';
import { X } from 'lucide-react';
import helpers from '@/global-components/components/helpers'
import { Spin } from 'react-cssfx-loading';

const PrintRequests = () => {
  const [printRequests, setPrintRequests] = useState<PrintRequest[]>([])
  const [deletingPrintRequests, setDeletingPrintRequests] = useState<string[]>([])
  const printRequestsQuery = useQuery(api.schedule.queries.GET_ALL_PRINT_REQUESTS, {
    pollInterval: 3000,
  })
  const [deletePrintRequestMutation] = useMutation(api.schedule.mutations.DELETE_PRINT_REQUEST)

  const deletePrintRequest = (id: string) => {
    setDeletingPrintRequests(prev => [...prev, id]);
    deletePrintRequestMutation({ variables: { requestId: id}})
      .then((result: any) => {
        setDeletingPrintRequests(prev => prev.filter(item => item !== id));
        setPrintRequests(prev => prev.filter(item => item.requestId !== id))
        printRequestsQuery.refetch()
      })
      .catch((error: any) => {  
        console.log(error)
      })
  }

  useEffect(() => {
    if (printRequestsQuery.data) {
      setPrintRequests(printRequestsQuery.data.printRequests)
    }
  }, [printRequestsQuery.data])
  return (
    <div className='print-requests p-4 '>
      <div className='print-requests p-4 rounded-md bg-white'>
        <Table className=''>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Request ID</TableHead>
              <TableHead>Quantity</TableHead>
              <TableHead>Requested By</TableHead>
              <TableHead>Created at</TableHead>
              <TableHead>File</TableHead>
              <TableHead>Filament</TableHead>
              <TableHead className='text-right'></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {printRequests.map((req: PrintRequest, index: number) => {
              return (
                <TableRow className={`${deletingPrintRequests.find(id => id === req.requestId) ? 'opacity-30 pointer-events-none' : ''}}`}>
                  <TableCell>{req.requestId}</TableCell>
                  <TableCell>{req.quantity}</TableCell>
                  <TableCell>{req.requestedBy.firstName}</TableCell>
                  <TableCell>{helpers.formatDateToDDMMYY(req.issuedAt)}</TableCell>
                  <TableCell>
                    <a href={req.file.presignedUrl} target='_blank' className='underline'>{req.file.fileName}</a>
                  </TableCell>
                  <TableCell>{req.filament.material.displayName} - {req.filament.colour.displayName}</TableCell>
                  <TableCell className='flex justify-end'>
                    <div className='flex items-center gap-1'>
                      <Button variant='bwsecondary'>  
                        Send to printer
                      </Button>
                      <Button variant='bwprimary' disabled>  
                        Schedule
                      </Button>
                      <Button 
                        variant='destructiveghost' 
                        onClick={() => deletePrintRequest(req.requestId)}>
                        {deletingPrintRequests.find(id => id === req.requestId) ?
                          <Spin className="inline-spin h-4 w-4" color="#36463D" width="20px" height="20px" duration="0.3s" />
                          :
                          <X className='h-4 w-4' />
                        }
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default PrintRequests