import {useState} from 'react';
import './Filament.scss';

import * as Types from '@/global-components/types'


export type FilamentProps = {
  color: string;
  active: boolean;
  currentFillInG?: number;
  fillMaxInG?: number;
  small?: boolean;
  med?: boolean;
}

const Filament = (props:FilamentProps): JSX.Element => {
  const ring_count: number = props.small ? 2 : (props.med ? 3 : 7);
  const [rings, setRings] = useState<boolean[]>(Array.from({length: ring_count}, () => true));  


  return (
    <div className={props.small ? "filament small" : (props.med ? "filament med" : "filament")}>
      {rings.map((ring_active, index) => (
        <div key={index} className={ring_active ? 'filament-ring active' : 'filament-ring' }
          style={{
            width: 100 - (100 / ring_count) * index + '%', 
            height: 100 - (100 / ring_count) * index + '%',
            borderColor: props.color,
          }}>

        </div>
      ))}
    </div>
  )
}

export default Filament;